body,
html {
font-family: Poppins !important;
background: #fff !important;
font-size: 16px;
overflow-x: hidden;
}

.containerBox {
max-width: 1440px;
margin: 0 auto;
}
.videoHeader{
background-image:url(../images/HeadGraphic.png);
background-repeat:no-repeat;
background-position:center;
background-size:cover;
height:420px;
width:100%;
padding:10px 70px;
}
.logo{
position:relative;
z-index:2;
float:left;
}
.logo img{
width:50%;
}
.pageHead {
font-family: Poppins;
font-size: 42px;
font-weight: 500;
letter-spacing: 0px;
color: #FFFFFF;
line-height: 42px;
padding-top:70px;
}
.pageHead span{
font-size:30px;
color:#000;
line-height:24px;
}
.andorFeatureDataWrapper {
background: #fff;
padding:70px;
position: relative;
}
.stepFlow img{
 width:73%;
}
.stepFlow .Step{
text-align:center;
font-weight:bold;
}
.stepappleFlow .StepApple, .stepappleFlow  p{
text-align:left;
}
.stepappleFlow .StepApple{
font-weight:bold;
}
.stepappleFlow img{
 width:85%;
}

.stepFlow p{
text-align:center;
}
.usingpc h2{
color:#48c3cc;
}
.brouseCompat img{
width:100%;
}
.clearfix{
clear:both;
}
.ipad img, .usingpc img{
 width:90%;
}
@media only screen and (max-width: 1366px) {
  /* For mobile phones: */
  .logo img{
  width:45%;
  height:45%;
  }
  .videoHeader{
  padding:10px 50px;
  }
  .usingpc h2{
  font-size:1.5em;
  }
  .pageHead{
   font-size:36px;
   line-height:36px;
  }
  .pageHead span{
  font-size:26px;
  }
  .andorFeatureDataWrapper{
  padding:50px;
  }
}
@media only screen and (max-width: 1024px) {
  /* For mobile phones: */
  .videoHeader{
  padding:10px 40px;
  }
  .logo img{
  width:35%;
  height:35%;
  }
  .usingpc h2{
  font-size:1.3em;
  }
  .pageHead{
   font-size:32px;
   line-height:32px;
  }
  .pageHead span{
  font-size:20px;
  }
  .andorFeatureDataWrapper{
  padding:40px;
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .videoHeader{
  padding:5px 20px;
  height:300px;
  }
  .logo img{
  width:25%;
  height:25%;
  }
  .usingpc h2, h3{
  font-size:1em;
  }
  .pageHead{
   font-size:24px;
   line-height:28px;
  }
  .pageHead span{
  font-size:16px;
  }
  .andorFeatureDataWrapper{
  padding:10px 30px;
  }
 .brouseCompat img{
 width:30%;
 }
 .stepappleFlow img{
  margin-bottom:50px;
 }
 .stepappleFlow .StepApple, .stepappleFlow p{
 text-align:center;
 }
 .stepappleFlow img{
 width:98%;
 }
}